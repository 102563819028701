import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BiChevronDown } from "react-icons/bi";
import { performApiRequest } from "src/utils/apiRequest";

const DropDownBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    width: 100%;
    padding: 4px 4px;
    font-size: 14px;
    cursor: pointer;
    gap: 7px;
    background-color: ${({ disabled }) => (disabled ? "#e0e0e0" : "transparent")};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

    &:hover {
        border: none;
        background-color: ${({ disabled }) => (disabled ? "#e0e0e0" : "lightgrey")};
        border-radius: 5px;
    }
`;

const DropDownBoxPlaceholder = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DropDownItemContainer = styled.div`
    width: 100%;
`;

const DropDownItems = styled.div`
    position: absolute;
    z-index: 20000;
    background-color: white;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    border-radius: 5px;
    min-height: 40px;
    min-width: 150px;
    max-width: 270px;
`;

const DropDownSearchStyle = styled.input`
    width: calc(100% - 7%);
    margin: 5px;
    padding: 8px 10px;
`;

const DropDownItemStyle = styled.div`
    width: calc(100% - 7%);
    margin: 5px;
    cursor: pointer;
    &:hover {
        background-color: lightgrey;
    }
`;

const ItemText = styled.div`
    padding: 5px 10px;
`;

export const ConditionalTableDataDropDown = (props) => {
    const { item, formData, dispatch, isBulk } = props;
    const [showDropDown, setShowDropDown] = useState(false);
    const [dropdownValues, setDropdownValues] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState(""); // Track the label
    const menuRef = useRef();
    const [isDisabled, setIsDisabled] = useState(true);

    // State to track tableRule values
    const [trackedValues, setTrackedValues] = useState({});

    // Function to replace placeholders in the payload
    const processPayload = (payload, formData) => {
        const replacePlaceholders = (obj) => {
            if (typeof obj === "string") {
                const match = obj.match(/{{formData\.(\w+)}}/); // Match {{formData.key}}
                if (match) {
                    const key = match[1]; // Extract the key
                    const value =
                        formData[key] !== undefined
                            ? formData[key]
                            : formData[key.charAt(0).toUpperCase() + key.slice(1)] || undefined;

                    if (value !== undefined) {
                        return value; // Replace placeholder with the found value
                    }
                }
                return obj; // Return original string if no match
            } else if (Array.isArray(obj)) {
                return obj.map(replacePlaceholders); // Recursively process arrays
            } else if (typeof obj === "object" && obj !== null) {
                return Object.keys(obj).reduce((acc, key) => {
                    acc[key] = replacePlaceholders(obj[key]); // Recursively process objects
                    return acc;
                }, {});
            }
            return obj; // Return primitive types as-is
        };

        return replacePlaceholders(payload);
    };

    // Check if tableRule values have changed
    useEffect(() => {
        const allRulesMet = item.tableRule.every((rule) => formData.hasOwnProperty(rule));
        setIsDisabled(!allRulesMet);

        if (allRulesMet) {
            // Extract current values for tableRule keys
            const currentValues = item.tableRule.reduce((acc, key) => {
                acc[key] = formData[key] || null;
                return acc;
            }, {});

            // Compare with trackedValues
            const hasChanged = item.tableRule.some((key) => trackedValues[key] !== currentValues[key]);

            if (hasChanged) {
                // Update trackedValues and call API
                setTrackedValues(currentValues);

                async function fetchDropdownData() {
                    if (item.values) {
                        try {
                            const processedPayload = processPayload(item.values, formData);
                            const previousResponse = await performApiRequest(
                                1,
                                "bexInsights",
                                "/bexinsights/search",
                                "post",
                                processedPayload
                            );

                            const tableRow = previousResponse?.data.tableRow || [];
                            const formattedValues = tableRow.map((row) => {
                                const otherFieldKey = Object.keys(row).find(
                                    (key) => key !== "recordId"
                                );
                                return {
                                    label: `${row[otherFieldKey] || "Unknown"}`,
                                    value: row.recordId,
                                };
                            });

                            setDropdownValues(formattedValues);
                        } catch (error) {
                            console.error("Error fetching dropdown data:", error);
                        }
                    }
                }

                fetchDropdownData();
            }
        }
    }, [item.tableRule, formData, trackedValues]);

    const setDropDownValue = (value, label) => {
        setShowDropDown(false);
        setSelectedLabel(label); // Store label to display

        if (isBulk !== undefined && isBulk) {
            props.setFormDataForField(value);
        } else {
            dispatch({
                type: "SET_FORM_DATA",
                payload: { ...formData, [item?.key || item?.field]: value },
            });
        }
    };

    return (
        <div
            ref={menuRef}
            style={{
                width: `${item.width ? item.width : "100%"}`,
            }}
        >
            <DropDownBox
                onClick={() => !isDisabled && setShowDropDown(!showDropDown)}
                disabled={isDisabled}
            >
                <DropDownBoxPlaceholder>
                    {selectedLabel || item.placeholder} {/* Show selected label */}
                </DropDownBoxPlaceholder>
                <div>
                    {showDropDown ? (
                        <BiChevronDown
                            className="drop-down-icon"
                            style={{
                                color: "black",
                                transform: "rotate(180deg)",
                            }}
                        />
                    ) : (
                        <BiChevronDown
                            className="drop-down-icon"
                            style={{ color: "black" }}
                        />
                    )}
                </div>
            </DropDownBox>
            <DropDownItemContainer>
                {showDropDown && (
                    <DropDownItems>
                        {item.search && (
                            <div>
                                <DropDownSearchStyle
                                    type="text"
                                    placeholder="Search"
                                />
                            </div>
                        )}
                        {dropdownValues.map((option, index) => (
                            <DropDownItemStyle
                                key={index}
                                onClick={() =>
                                    setDropDownValue(option.value, option.label)
                                }
                            >
                                <ItemText>{option.label}</ItemText>
                            </DropDownItemStyle>
                        ))}
                    </DropDownItems>
                )}
            </DropDownItemContainer>
        </div>
    );
};
