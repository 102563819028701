import { useState } from "react"
import { Link } from 'react-router-dom';
// import Link from "next/link"
import { Button } from "src/components/ui/button"
import { Card, CardContent } from "src/components/ui/card"
import { Play, Code, Brain, Target, Facebook, Twitter, Linkedin, CuboidIcon as Cube, Layers, Zap, Scale } from 'lucide-react'
import { Dialog, DialogContent } from "src/components/ui/dialog"

// Load package.json using require
const packageJson = require('../../../package.json'); // Adjust the path as needed



function VideoModal({ isOpen, onClose, videoUrl }) {
    const videoId = videoUrl.split('v=')[1]?.split('&')[0]

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[800px] p-0">
                <div className="aspect-video w-full">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qX_9NoCjegU?si=fYnD4GfenLOSQqCG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default function Home() {
    const [isVideoOpen, setIsVideoOpen] = useState(false)
    const { name, version } = packageJson;  // Destructure the properties

    return (
        <div className="min-h-screen bg-white text-gray-800">


            <main>
                {/* Hero Section */}
                <section className="py-20 md:py-32 bg-gradient-to-b from-gray-50 to-white relative  overflow-hidden">
                    <div className="container mx-auto px-4 grid md:grid-cols-2 gap-12 items-center relative  z-10">
                        <div>
                            <div className="text-sm font-medium text-[#B1092A] mb-4">Next-Gen 3D App Platform</div>
                            <h1 className="text-4xl md:text-6xl font-bold mb-6 text-gray-900">
                                Master <span className="text-[#B1092A]">3D Skills</span> for the Future
                            </h1>
                            <p className="text-gray-600 mb-8 text-lg">
                                Experience the future of 3D technology with our cutting-edge app ecosystem. Create, innovate, and transform your ideas into reality.
                            </p>
                            <div className="flex flex-wrap gap-4">
                                <Button className="bg-[#B1092A] hover:bg-[#8E0721] text-white" asChild>
                                    <Link href="https://bex.coolriots.ai/auth/login">Start Now</Link>
                                </Button>
                                <Button
                                    variant="outline"
                                    className="gap-2 text-[#B1092A] border-[#B1092A] hover:bg-[#FDE8EC]"
                                    onClick={() => setIsVideoOpen(true)}
                                >
                                    <Play className="w-4 h-4" /> Watch Demo
                                </Button>
                            </div>
                        </div>
                        <div className="relative">
                            <img
                                className="w-full rounded-lg shadow-2xl transform hover:scale-105 transition-transform duration-300"
                                src="https://images.unsplash.com/photo-1617791160505-6f00504e3519?q=80&w=3456&h=2304"
                                alt="3D modeling workspace with computer screens showing 3D software"
                                crossOrigin="anonymous"
                            />
                            <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-[#B1092A] rounded-full flex items-center justify-center text-2xl font-bold text-white animate-bounce shadow-lg">
                                BeX3D
                            </div>
                        </div>
                    </div>
                    <div className="absolute top-1/2 right-0 transform -translate-y-1/2 w-1/3 h-2/3">
                        <div className="w-full h-full bg-[#B1092A] rounded-l-full opacity-20 blur-3xl"></div>
                    </div>
                </section>

                {/* Learning Platforms */}
                <section className="py-20 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <div className="text-center mb-2 text-[#B1092A] font-medium">App Ecosystem</div>
                        <h2 className="text-3xl md:text-5xl font-bold text-center mb-4 text-gray-900">
                            Explore Our <span className="text-[#B1092A]">3D App</span>  Suite
                        </h2>
                        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                            Dive into our comprehensive suite of 3D applications designed to revolutionize your workflow and unleash your creative potential.
                        </p>
                        <div className="grid md:grid-cols-3 gap-8">
                            {[
                                {
                                    icon: <Layers className="w-12 h-12 text-[#B1092A] mb-4" />,
                                    title: "Ascendo BesTraining",
                                    description: "Advanced enterprise 3D training platform for professional development.",
                                    link: "/64f9f31ab8f2b3a65cbfc48d/Login"
                                },
                                {
                                    icon: <Cube className="w-12 h-12 text-[#B1092A] mb-4" />,
                                    title: "Ascendo Production",
                                    description: "Comprehensive 3D modeling and animation training courses.",
                                    link: "/64f9f31ab8f2b3acae3x4sfe/Login"
                                },
                                {
                                    icon: <Zap className="w-12 h-12 text-[#B1092A] mb-4" />,
                                    title: "AiBBLe",
                                    description: "AI-powered 3D learning assistant for personalized education.",
                                    link: "/6731ec4e94e023f1ce6016b5/Login"
                                },
                                {
                                    icon: <Scale className="w-12 h-12 text-[#B1092A] mb-4" />,
                                    title: "Legal Workflow",
                                    description: "Streamline your legal processes with our advanced workflow automation platform.",
                                    link: "/665467d84b7c867c744381a0/Login"
                                }
                            ].map((platform, index) => (
                                <Card key={index} className="bg-white border-gray-200 hover:shadow-xl transition-all group">
                                    <CardContent className="p-6 text-center">
                                        <div className="transform group-hover:scale-110 transition-transform duration-300">
                                            {platform.icon}
                                        </div>
                                        <h3 className="text-xl font-semibold mb-3 text-gray-800">{platform.title}</h3>
                                        <p className="text-gray-600 mb-4">{platform.description}</p>
                                        <Button variant="link" className="text-[#B1092A] hover:text-[#8E0721] p-0" asChild>
                                            <Link to={platform.link}>Explore Platform →</Link>
                                        </Button>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Features */}
                <section className="py-20 bg-white">
                    <div className="container mx-auto px-4">
                        <div className="text-center mb-2 text-[#B1092A] text-sm font-medium">Platform Highlights</div>
                        <h2 className="text-3xl md:text-5xl font-bold text-center mb-4 text-gray-900">
                            Why Choose BeX3D <span className="text-[#B1092A]">Platform</span>
                        </h2>
                        <p className="text-center text-gray-600 mb-12 max-w-3xl mx-auto text-lg">
                            Harness the power of our integrated 3D app platform, equipped with cutting-edge tools and AI-driven capabilities.
                        </p>
                        <div className="grid md:grid-cols-3 gap-8">
                            {[
                                {
                                    icon: <Brain className="w-12 h-12" />,
                                    title: "AI-Powered Apps",
                                    description: "Harness the power of artificial intelligence to enhance your 3D workflows and boost productivity.",
                                    image: "https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80&w=800&ixlib=rb-4.0.3"
                                },
                                {
                                    icon: <Code className="w-12 h-12" />,
                                    title: "Multi-modal interface",
                                    description: "Seamlessly switch between different interaction modes for an intuitive and flexible user experience.",
                                    image: "https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?auto=format&fit=crop&q=80&w=800&ixlib=rb-4.0.3",
                                    featured: true
                                },
                                {
                                    icon: <Target className="w-12 h-12" />,
                                    title: "CUI-GUI Interaction",
                                    description: "Effortlessly combine command-line and graphical interfaces for maximum control and efficiency.",
                                    image: "https://images.unsplash.com/photo-1507207611509-ec012433ff52?auto=format&fit=crop&q=80&w=800&ixlib=rb-4.0.3"
                                }
                            ].map((feature, index) => (
                                <Card
                                    key={index}
                                    className={`bg-white transition-all group p-8 ${feature.featured
                                        ? 'border-[#B1092A] shadow-lg'
                                        : 'border-gray-200 hover:shadow-lg'
                                        }`}
                                >
                                    <CardContent className="p-0 space-y-6">
                                        <div className={`rounded-full w-16 h-16 flex items-center justify-center ${feature.featured ? 'bg-[#B1092A] text-white' : 'bg-gray-100 text-[#B1092A]'
                                            }`}>
                                            {feature.icon}
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold mb-3 text-gray-900">{feature.title}</h3>
                                            <p className="text-gray-600">{feature.description}</p>
                                        </div>
                                        <div className="aspect-video w-full bg-gray-100 rounded-lg overflow-hidden">
                                            <img
                                                src={feature.image}
                                                alt={feature.title}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Video Modal */}
                <VideoModal
                    isOpen={isVideoOpen}
                    onClose={() => setIsVideoOpen(false)}
                    videoUrl="https://youtu.be/qX_9NoCjegU?si=e9GqBCWvxDbteT4S"
                />
            </main>

            {/* Footer */}
            <footer className="bg-gray-100 border-t border-gray-200">
                <div className="border-t border-gray-200 pt-8 text-center text-gray-600">
                    &copy; {new Date().getFullYear()} {name}. BeX3D Academy. All rights reserved. v{version}
                </div>
            </footer>
        </div>
    )
}

