import { styled } from "styled-components";
import { InputField, RadioField } from "./InputField";
import { DropDownField } from "./DropDown";
import { TableDataDropDown } from "./TableDataDropDown";
import { ConditionalTableDataDropDown } from "./ConditionalTableDataDropDown";
import FileUploadField from "./FileUploader.jsx";
import { DropDownFieldWithCondition } from "./ConditionalDropDown";
import { Label } from "./ui/label"
import React from "react";
// import {
//     Form,
//     FormField,
//     FormItem,
//     FormLabel,
//     FormControl,
//     FormDescription,
//     FormMessage
// } from './ui/form';

// here
const FormFieldContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
    width: 100%;
    padding: 10px;
    background: #F8F9FA;
    border-radius: 8px;
    margin-top:50px;

    @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const FormField = styled.div`
    padding: 20px 15px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    min-height: 115px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const FormFieldTitle = styled.label`
    color: #black;
    font-weight: 500;
    display: block;
    padding-bottom: 10px;
`;

const FormFieldInputError = styled.span`
    color: #b1092a;
    display: block;
    font-size: 12px;
    padding-top: 5px;
    font-style: italic;
`;

export function SearchForm({ formData, fields, windowWidth, dispatch }) {
    return (
        <FormFieldContainer>
            {fields.map((item, index) => (
                <div
                    key={index}
                    className="form-field-container"
                    style={{
                        gridColumn: `${windowWidth > 700
                            ? item.visible !== false
                                ? `span ${item?.size}`
                                : null
                            : "span 1"
                            }`,
                        display: `${item.visible !== false ? "block" : "none"}`,
                    }}
                >
                    {item.visible !== false &&
                        ["text", "number", "date", "email"].includes(
                            item.type || item.inputType,
                        ) && (
                            <div className="w-full" key={index}>
                                <FormFieldTitle htmlFor={item.key}>
                                    {item.label}
                                </FormFieldTitle>
                                <InputField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                            </div>
                        )}
                    {item.visible !== false &&
                        (item.type === "dropdown" ||
                            item.inputType === "dropdown") && (
                            <div className="w-full" key={index}>
                                <FormFieldTitle htmlFor={item.key}>
                                    {item.label}
                                </FormFieldTitle>
                                <DropDownField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                            </div>
                        )}
                    {item.visible !== false &&
                        (item.type === "radio" ||
                            item.inputType === "radio") && (
                            <div className="w-full" key={index}>
                                <FormFieldTitle htmlFor={item.key}>
                                    {item.label}
                                </FormFieldTitle>
                                <RadioField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                            </div>
                        )}
                </div>
            ))}
        </FormFieldContainer>
    );
}

export function CreateForm({
    formData,
    fields,
    windowWidth,
    fieldErrors,
    dispatch,
}) {
    const [dynamicValidations, setDynamicValidations] = React.useState([]);
    console.log("Form Data ", formData, fields)
    return (
        <FormFieldContainer>
            {fields.map((item, index) => (
                <div
                    key={index}
                    className="form-field-container"
                    style={{
                        gridColumn: `${windowWidth > 700
                            ? item.visible !== false
                                ? `span ${item?.size}`
                                : null
                            : "span 1"
                            }`,
                        display: `${item.visible !== false ? "block" : "none"}`,
                    }}
                >
                    {item.visible !== false &&
                        ["text", "number", "date", "email"].includes(
                            item.type || item.inputType,
                        ) && (
                            <FormField>
                                {/* <FormFieldTitle
                                    htmlFor={item.key || item.field}
                                >
                                    {item.label} {item.required && "*"}
                                </FormFieldTitle> */}
                                <div className="space-y-2">
                                    <Label htmlFor={item.key || item.field}>{item.label} {item.required && "*"}</Label></div>
                                <InputField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                    dynamicValidations={dynamicValidations}
                                // readOnly={item.readonly === true}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}
                    {item.visible !== false &&
                        (item.type || item.inputType) === "dropdown" && (
                            <FormField>
                                <div className="space-y-2">
                                    <Label htmlFor={item.key || item.field}>{item.label} {item.required && "*"}</Label></div>
                                <DropDownField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}
                    {item.visible !== false &&
                        (item.type || item.inputType) === "dropdownCondition" && (
                            <FormField>
                                <div className="space-y-2">
                                    <Label htmlFor={item.key || item.field}>{item.label} {item.required && "*"}</Label></div>
                                <DropDownFieldWithCondition
                                    item={item}
                                    formData={formData}
                                    autoPopulate={item.autoPopulate}
                                    dispatch={dispatch}
                                    setDynamicValidations={setDynamicValidations}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}

                    {/* {item.visible !== false &&
                        (item.type || item.inputType) === "fileUpload" && (
                            <FormField>
                                <div className="space-y-2">
                                    <Label htmlFor={item.key || item.field}>{item.label} {item.required && "*"}</Label></div>
                                <DropDownFieldWithCondition
                                    item={item}
                                    formData={formData}
                                    autoPopulate={item.autoPopulate}
                                    dispatch={dispatch}
                                    setDynamicValidations={setDynamicValidations}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )} */}

                    {item.visible !== false &&
                        (item.type || item.inputType) === "fileUpload" && (
                            <FormField>
                                <Label htmlFor={item.key || item.field}>
                                    {item.label} {item.required && "*"}
                                </Label>
                                <FileUploadField
                                    onFileUpload={(fileData) => {
                                        // Update formData with the uploaded file information
                                        dispatch({
                                            type: "SET_FORM_DATA",
                                            payload: {
                                                ...formData,
                                                [item.key || item.field]: fileData,
                                            },
                                        });
                                    }}
                                />
                                {/* Display error message for validation errors */}
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {fieldErrors[item.key || item.field]}
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}


                    {item.visible !== false &&
                        (item.type || item.inputType) === "radio" && (
                            <FormField>
                                <div className="space-y-2">
                                    <Label htmlFor={item.key || item.field}>{item.label} {item.required && "*"}</Label></div>
                                <RadioField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}

                    {item.visible !== false && (item.type || item.inputType) === "tableDataDropDown" && (
                        <FormField>
                            <div className="space-y-2">
                                <Label htmlFor={item.key || item.field}>
                                    {item.label} {item.required && "*"}
                                </Label>
                            </div>
                            <TableDataDropDown
                                item={item}
                                formData={formData}
                                dispatch={dispatch}
                            />
                            {fieldErrors &&
                                fieldErrors[item.key || item.field] &&
                                !formData[item.key || item.field] && (
                                    <FormFieldInputError>
                                        {fieldErrors[item.key || item.field]}
                                    </FormFieldInputError>
                                )}
                        </FormField>
                    )}

                    {item.visible !== false && (item.type || item.inputType) === "ConditionalTableDataDropDown" && (
                        <FormField>
                            <div className="space-y-2">
                                <Label htmlFor={item.key || item.field}>
                                    {item.label} {item.required && "*"}
                                </Label>
                            </div>
                            <ConditionalTableDataDropDown
                                item={item}
                                formData={formData}
                                dispatch={dispatch}
                            />
                            {fieldErrors &&
                                fieldErrors[item.key || item.field] &&
                                !formData[item.key || item.field] && (
                                    <FormFieldInputError>
                                        {fieldErrors[item.key || item.field]}
                                    </FormFieldInputError>
                                )}
                        </FormField>
                    )}


                </div>
            ))}
        </FormFieldContainer>
    );
}