import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BiChevronDown } from "react-icons/bi";
import { performApiRequest } from "src/utils/apiRequest";

const DropDownBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    width: 100%;
    padding: 4px 4px;
    font-size: 14px;
    cursor: pointer;
    gap: 7px;

    &:hover {
        border: none;
        background-color: lightgrey;
        border-radius: 5px;
    }
`;

const DropDownBoxPlaceholder = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DropDownItemContainer = styled.div`
    width: 100%;
`;

const DropDownItems = styled.div`
    position: absolute;
    z-index: 20000;
    background-color: white;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    border-radius: 5px;
    min-height: 40px;
    min-width: 150px;
    max-width: 270px;
`;

const DropDownSearchStyle = styled.input`
    width: calc(100% - 7%);
    margin: 5px;
    padding: 8px 10px;
`;

const DropDownItemStyle = styled.div`
    width: calc(100% - 7%);
    margin: 5px;
    cursor: pointer;
    &:hover {
        background-color: lightgrey;
    }
`;

const ItemText = styled.div`
    padding: 5px 10px;
`;
export const TableDataDropDown = (props) => {
    const { item, formData, dispatch, isBulk } = props;
    const [showDropDown, setShowDropDown] = useState(false);
    const [dropdownValues, setDropdownValues] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState(""); // Track the label
    const menuRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowDropDown(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        async function fetchDropdownData() {
            if (item.values) {
                try {
                    const payLoad = item.values;
                    const previousResponse = await performApiRequest(
                        1,
                        "bexInsights",
                        "/bexinsights/search",
                        "post",
                        payLoad
                    );

                    console.log("API Response:", previousResponse.data.tableRow);

                    const tableRow = previousResponse?.data.tableRow || [];
                    const formattedValues = tableRow.map((row) => {
                        const otherFieldKey = Object.keys(row).find(
                            (key) => key !== "recordId"
                        );
                        const label = `${row[otherFieldKey] || "Unknown"}`;
                        return {
                            label: label,
                            value: row.recordId,
                        };
                    });

                    console.log("Formatted Dropdown Values:", formattedValues);
                    setDropdownValues(formattedValues);
                } catch (error) {
                    console.error("Error fetching dropdown data:", error);
                }
            }
        }

        fetchDropdownData();
    }, [item.values]);

    const setDropDownValue = (value, label) => {
        setShowDropDown(false);
        setSelectedLabel(label); // Store label to display

        if (isBulk !== undefined && isBulk) {
            props.setFormDataForField(value);
        } else {
            dispatch({
                type: "SET_FORM_DATA",
                payload: { ...formData, [item?.key || item?.field]: value },
            });
        }
    };

    return (
        <div
            ref={menuRef}
            style={{
                width: `${item.width ? item.width : "100%"}`,
            }}
        >
            <DropDownBox onClick={() => setShowDropDown(!showDropDown)}>
                <DropDownBoxPlaceholder>
                    {selectedLabel || item.placeholder} {/* Show selected label */}
                </DropDownBoxPlaceholder>
                <div>
                    {showDropDown ? (
                        <BiChevronDown
                            className="drop-down-icon"
                            style={{
                                color: "black",
                                transform: "rotate(180deg)",
                            }}
                        />
                    ) : (
                        <BiChevronDown
                            className="drop-down-icon"
                            style={{ color: "black" }}
                        />
                    )}
                </div>
            </DropDownBox>
            <DropDownItemContainer
                style={{
                    boxShadow: `${showDropDown ? "0px 0px 5px 2px rgba(0, 0, 0, 0.2)" : ""
                        }`,
                    position: `${isBulk !== undefined && isBulk ? "" : "relative"
                        }`,
                }}
            >
                {showDropDown && (
                    <DropDownItems>
                        {item.search && (
                            <div>
                                <DropDownSearchStyle
                                    type="text"
                                    placeholder="Search"
                                />
                            </div>
                        )}
                        {dropdownValues.map((option, index) => (
                            <DropDownItemStyle
                                key={index}
                                onClick={() =>
                                    setDropDownValue(option.value, option.label)
                                }
                            >
                                <ItemText>{option.label}</ItemText>
                            </DropDownItemStyle>
                        ))}
                    </DropDownItems>
                )}
            </DropDownItemContainer>
        </div>
    );
};
