import { httpRequest3, httpRequest1, httpRequest2 } from "../utils/httpsRequest";

function deepAssign(target, source) {
    Object.keys(source).forEach(key => {
        if (source[key] && typeof source[key] === 'object') {
            target[key] = deepAssign(target[key] || {}, source[key]);
        } else {
            target[key] = source[key];
        }
    });
    return target;
}

export function createDynamicPayload(data, config, extra_data = null) {
    const payload = {};
    const newData = Object.assign({}, data, extra_data)

    console.log("Data ", newData)

    config.payloadFields.forEach(field => {
        if (typeof field === 'string') {
            // Direct mapping from data to payload
            payload[field] = newData[field];
        } else if (typeof field === 'object') {
            // Check conditions if present
            if (field.condition && !field.condition(newData)) {
                return;
            }
            // Assign computed values, handle nested objects recursively
            payload[field.name] = typeof field.value === 'function' ? field.value(newData) : deepAssign({}, field.value);
        }
    });

    return payload;
}


// Enhanced API request function
export const performApiRequest = async (server = 1, key, url, method, payload = null, successMessage = null, errorMessage = null, addNewNotification = null,token = null,handleClear = null) => {
    var response = {}
    //temporary 
    const userData = JSON.parse(localStorage.getItem("userData")); // Retrieve userData from localStorage
    const { access_token: accesstoken } = userData || {}; // Extract tenant_id safely
    try {
        if (server === 1){
            response = await httpRequest1[method](url, payload, {
                headers: {
                    'access-token': `Bearer ${accesstoken}` 
                }
            });
        }
        if (server === 2){
            response = await httpRequest2[method](url, payload);
        }
        if (server === 3){
            //response = await httpRequest3[method](url, payload);
            response = await httpRequest3[method](url, payload, {
                headers: {
                    'access-token': `Bearer ${token}` 
                }
            });
        }
        console.log("API Response", response);
        
        if (key === "bexAction" && (successMessage || errorMessage)){
            if (response.data && response.data.bulkCount.success === 1) {
                addNewNotification(successMessage, 'success');
                return response;  // Return data for further processing if needed
            } else {
                addNewNotification(errorMessage, 'danger');
            }
        }
        else if(key === "bookingRegAPI" && (successMessage || errorMessage)){
            if (response.data && response.data.status === 200) {
                addNewNotification(successMessage, 'success');
                return response;
            } else if (response.data && response.data.status !== 200) {
                addNewNotification(response.data.message, 'danger');
            }
        }
        return response;
    } catch (error) {
        console.log(error);
        // if (successMessage || errorMessage){
            // addNewNotification(errorMessage, 'danger');
        // }
    }
};
