// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
}

/* .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100%;
    margin: 0 auto;
   
} */

/* .container > main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    
} */

.container > main > div {
    /* padding: 0 2rem;
    padding-bottom: 2rem; */
}

.container > footer,
.container > header {
    flex-shrink: 0;
}

/* @media screen and (max-width: 1279px) {
    .container > main > div {
        padding: 0 1rem;
        padding-bottom: 1rem;
    }
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;;;;;;;GAOG;;AAEH;;;;;;GAMG;;AAEH;IACI;2BACuB;AAC3B;;AAEA;;IAEI,cAAc;AAClB;;AAEA;;;;;GAKG","sourcesContent":[".App {\n    text-align: center;\n}\n\n/* .container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    max-width: 100%;\n    margin: 0 auto;\n   \n} */\n\n/* .container > main {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    min-height: 80vh;\n    \n} */\n\n.container > main > div {\n    /* padding: 0 2rem;\n    padding-bottom: 2rem; */\n}\n\n.container > footer,\n.container > header {\n    flex-shrink: 0;\n}\n\n/* @media screen and (max-width: 1279px) {\n    .container > main > div {\n        padding: 0 1rem;\n        padding-bottom: 1rem;\n    }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
