// import React from "react";
// import styled from "styled-components";
// import {
//     AiOutlineVerticalRight,
//     AiOutlineLeft,
//     AiOutlineRight,
//     AiOutlineVerticalLeft,
// } from "react-icons/ai";

// const PaginationContainer = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     flex-wrap: wrap;
//     gap: 10px;
//     width: 100%;
//     margin: auto;
//     padding: 0px 40px;
//     color: #000000;

//     @media (max-width: 768px) {
//         flex-direction: column;
//         padding: 0px;
//     }
// `;

// const RowNumberContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;

//     @media (max-width: 768px) {
//         display: none;
//     }
// `;

// const ButtonContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 5px;
// `;

// const PageNumberContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;
//     padding: 0px 10px;
// `;

// const ButtonStyle = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;

// const NumberOfRowsInput = styled.input`
//     width: 12%;
//     height: 30px;
//     padding: 0px 5px;
//     text-align: center;
//     border: 1px solid #b0bec5;
//     border-radius: 5px;

//     &:disabled {
//         background-color: #f5f5f5;
//         color: "#111111";
//         cursor: "not-allowed";
//         font-weight: 500;
//     }
// `;

// const activeButton = {
//     color: "#bb0000",
//     cursor: "pointer",
// };

// const disabledButton = {
//     color: "#b0bec5",
//     cursor: "not-allowed",
// };

// export const Pagination = ({
//     currentPage,
//     maxNumberOfRows = 20,
//     totalFetchedRow,
//     totalRows,
//     totalPages,
//     onPageChange,
//     setFetching,
// }) => {
//     const isFirstPage = currentPage === 1;
//     const isLastPage = currentPage === totalPages;

//     // Calculate the range of pages to display (5 pages total)
//     const pageRange = Math.min(totalPages, 5);

//     // Calculate the starting and ending page numbers for the pagination
//     let startPage = Math.max(currentPage - Math.floor(pageRange / 2), 1);
//     let endPage = startPage + pageRange - 1;

//     if (endPage > totalPages) {
//         endPage = totalPages;
//         startPage = Math.max(endPage - pageRange + 1, 1);
//     }

//     const pageNumbers = [];
//     for (let i = startPage; i <= endPage; i++) {
//         pageNumbers.push(i);
//     }

//     return (
//         <PaginationContainer>
//             <RowNumberContainer>
//                 <div>Item Per Page</div>
//                 <NumberOfRowsInput
//                     type="number"
//                     value={maxNumberOfRows}
//                     disabled={true}
//                 />
//                 <div>
//                     {(currentPage - 1) * maxNumberOfRows + 1} -{" "}
//                     {(currentPage - 1) * maxNumberOfRows + totalFetchedRow} of{" "}
//                     {totalRows}
//                 </div>
//             </RowNumberContainer>

//             <ButtonContainer>
//                 <ButtonStyle
//                     style={isFirstPage ? disabledButton : activeButton}
//                     onClick={() => {
//                         onPageChange(1);
//                         setFetching(true);
//                     }}
//                 >
//                     <AiOutlineVerticalRight />
//                 </ButtonStyle>
//                 <ButtonStyle
//                     style={isFirstPage ? disabledButton : activeButton}
//                     onClick={() => {
//                         onPageChange(currentPage - 1);
//                         setFetching(true);
//                     }}
//                 >
//                     <AiOutlineLeft />
//                     Previous
//                 </ButtonStyle>
//                 <PageNumberContainer>
//                     {currentPage} of {totalPages}
//                 </PageNumberContainer>
//                 <ButtonStyle
//                     style={isLastPage ? disabledButton : activeButton}
//                     onClick={() => {
//                         onPageChange(currentPage + 1);
//                         setFetching(true);
//                     }}
//                 >
//                     Next
//                     <AiOutlineRight />
//                 </ButtonStyle>
//                 <ButtonStyle
//                     style={isLastPage ? disabledButton : activeButton}
//                     onClick={() => {
//                         onPageChange(totalPages);
//                         setFetching(true);
//                     }}
//                 >
//                     <AiOutlineVerticalLeft />
//                 </ButtonStyle>
//             </ButtonContainer>
//         </PaginationContainer>
//     );
// };


import React from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "lucide-react";

// Styled Components
const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PageButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    color: #b0bec5;
    background-color: transparent;
  }

  &.active {
    background-color:rgb(0, 0, 0);
    color: #fff;
  }
`;

const Ellipsis = styled.span`
  padding: 0 5px;
  font-size: 14px;
  color: #b0bec5;
`;

// Pagination Component
export const Pagination = ({
    currentPage,
    maxNumberOfRows = 10,
    totalFetchedRow,
    totalRows,
    totalPages,
    onPageChange,
    setFetching,
}) => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const handlePageChange = (page) => {
        if (page === currentPage) return;
        onPageChange(page);
        setFetching(true);
    };

    const renderPageNumbers = () => {
        const pages = [];

        // Always show the first page
        pages.push(
            React.createElement(Button, {
                key: 1,
                className: currentPage === 1 ? "active" : "",
                onClick: () => handlePageChange(1),
            }, "1")
        );

        // Calculate range around the current page
        let start = Math.max(2, currentPage - 2);
        let end = Math.min(totalPages - 1, currentPage + 2);

        // Add ellipsis after the first page
        if (start > 2) {
            pages.push(React.createElement(Ellipsis, { key: "ellipsis1" }, "..."));
        }

        // Add pages around the current page
        for (let i = start; i <= end; i++) {
            pages.push(
                React.createElement(Button, {
                    key: i,
                    className: currentPage === i ? "active" : "",
                    onClick: () => handlePageChange(i),
                }, i.toString())
            );
        }

        // Add ellipsis before the last page
        if (end < totalPages - 1) {
            pages.push(React.createElement(Ellipsis, { key: "ellipsis2" }, "..."));
        }

        // Always show the last page
        if (totalPages > 1) {
            pages.push(
                React.createElement(Button, {
                    key: totalPages,
                    className: currentPage === totalPages ? "active" : "",
                    onClick: () => handlePageChange(totalPages),
                }, totalPages.toString())
            );
        }

        return pages;
    };

    const showingStart = (currentPage - 1) * maxNumberOfRows + 1;
    const showingEnd = Math.min(currentPage * maxNumberOfRows, totalRows);

    return React.createElement(
        PaginationContainer,
        {},
        React.createElement(
            "div",
            { className: "text-sm text-muted-foreground" },
            `Showing ${showingStart} to ${showingEnd} of ${totalRows} results`
        ),
        React.createElement(
            PageButtonGroup,
            {},
            React.createElement(
                Button,
                {
                    disabled: isFirstPage,
                    onClick: () => handlePageChange(currentPage - 1),
                },
                React.createElement(ChevronLeft, { className: "h-4 w-4 mr-1" }),
                "Previous"
            ),
            ...renderPageNumbers(),
            React.createElement(
                Button,
                {
                    disabled: isLastPage,
                    onClick: () => handlePageChange(currentPage + 1),
                },
                "Next",
                React.createElement(ChevronRight, { className: "h-4 w-4 ml-1" })
            )
        )
    );
};

