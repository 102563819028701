// import React from "react";
// import styled, { keyframes } from "styled-components";

// // Define a CSS keyframe for smooth rotation
// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// `;

// // Loader container with the smooth rotation animation
// const LoaderContainer = styled.div`
//   display: inline-block;
//   animation: ${rotate} 1s linear infinite;  // Smooth rotation
// `;

// // Centered within its parent container, but not fullscreen
// const AppLoaderContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;  // Full width of the container
//   height: 100%;  // Full height of the container
// `;

// // Main loading spinner component
// export const LoaderSpinner = ({ size = 24, color = 'text-primary' }) => {
//   return (
//     <LoaderContainer aria-label="Loading">
//       <svg
//         className={color}  // Dynamically apply color class
//         width={size}
//         height={size}
//         viewBox="0 0 24 24"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M12 4.75V6.25"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M17.1266 6.87347L16.0659 7.93413"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M19.25 12L17.75 12"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M17.1266 17.1265L16.0659 16.0659"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M12 17.75V19.25"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M7.9342 16.0659L6.87354 17.1265"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M6.25 12L4.75 12"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M7.9342 7.93413L6.87354 6.87347"
//           stroke="currentColor"
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//     </LoaderContainer>
//   );
// };

// // Main AppLoader component to use within any container
// export const AppLoader = ({ size = 48, color = 'text-primary' }) => {
//   return (
//     <AppLoaderContainer>
//       <LoaderSpinner size={size} color={color} />
//     </AppLoaderContainer>
//   );
// };


// // Loader Inside a Section: If you place the AppLoader inside any component or div, it will center the spinner within that specific container. For example:
// // jsx
// // Copy code:


// // <div style={{ height: '300px', width: '100%', background: '#f0f0f0' }}>
// //   {/* AppLoader will be centered within this div */}
// //   <AppLoader size={40} color="text-blue-500" />
// // </div>


// // Full Page Loader: If you want to center the spinner for the entire page, you can still do that by wrapping it inside a full-page container like this:
// // jsx
// // Copy code:


// // <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
// //   <AppLoader size={48} color="text-blue-500" />
// // </div>


import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai'
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const LoaderContainer = styled.div`
    animation: ${rotate} 1s infinite linear;
`;

const AppLoaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoaderContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: 100vh;
`


export const LoaderSpinner = ({ ...loadingProps }) => {

  return (
    <LoaderContainer>
      <AiOutlineLoading {...loadingProps} />
    </LoaderContainer>
  )
}

export const AppLoader = ({ ...loadingProps }) => {

  return (
    <AppLoaderContainer>
      <LoaderContainer>
        <AiOutlineLoading {...loadingProps} />
      </LoaderContainer>
    </AppLoaderContainer>
  )
}
