/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState, useMemo } from "react";
import { httpRequest, httpRequest2, httpRequest3 } from "../utils/httpsRequest";
import { AppLoader } from "./LoaderSpinner";
import { PrimaryButton } from "./Buttons";
import { CreateForm } from "./Form";
import Card2 from "./Card";
import { useContext } from "react";
import { UserStoryContext } from "../context/UserStoryState";
import { Context } from "../context/GlobalState";
import { apiConfig } from "../utils/api/config";
import { performApiRequest } from "../utils/apiRequest";
// import { UserStoryContext } from "../context/UserStoryState";
import axios from "axios";
const initialCreateState = {
  template: {
    templateName: "",
    tableName: "",
    formComponent: {},
  },
  formData: {},
  fieldErrors: {},
  fieldValidateValues: {},
  prevFrameData: {},
  loading: true,
};

// const { SetViewDetailData,viewDetailRecordID,AddContextData, } = useContext(UserStoryContext);
const createReducer = (state, action) => {
  switch (action.type) {
    case "SET_TEMPLATE":
      return { ...state, template: action.payload };
    case "SET_FORM_DATA":
      return { ...state, formData: action.payload };
    case "SET_FIELD_ERRORS":
      return { ...state, fieldErrors: action.payload };
    case "SET_FIELD_VALIDATE_VALUES":
      return { ...state, fieldValidateValues: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_PREV_FRAME_DATA":
      return { ...state, prevFrameData: action.payload };
    default:
      return state;
  }
};
function createNewDictWithKeys(originalDict, keys) {
  return keys.reduce((newDict, key) => {
    if (key in originalDict) {
      newDict[key] = originalDict[key];
    }
    return newDict;
  }, {});
}
const transformData = (inputData) => {
  const valuesArray = Object.values(inputData);

  const transformedData = valuesArray.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  const finalData = {};

  Object.keys(transformedData).forEach((key) => {
    if (key.endsWith("_recid")) {
      const newKey = key.slice(0, -6);
      finalData[newKey] = transformedData[key];
    } else {
      // If the key does not end with '_recid', keep it as is
      finalData[key] = transformedData[key];
    }
  });

  return finalData;
};

export default function OpCodeCreateForm({
  templateName,
  prevFrame = null,
  isUserStory = false,
  filter = null,
  apiCalls,
  autofillup = [],
  submitbtn = "Submit",
  allowedSubmitFields = false
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loadingscreen, setLoadingscreen] = useState(false)
  const { addNewNotifcation } = useContext(Context);
  const { GetPreviousContextData, AddContextData } =
    useContext(UserStoryContext);
  const [state, dispatch] = useReducer(createReducer, initialCreateState);
  const { template, formData, prevFrameData, fieldErrors, loading } = state;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    const getTemplate = async () => {

      if (isUserStory && prevFrame !== null) {
        console.log(
          "pre contest create",
          transformData(GetPreviousContextData())
        );
        const prevFrameDataTemp = transformData(GetPreviousContextData());
        console.log("Frame data", prevFrameDataTemp.token);
        if (prevFrameDataTemp) {
          dispatch({
            type: "SET_FORM_DATA",
            payload: prevFrameDataTemp,
          });
        }
      }

      let previousResponse = null;

      if (apiCalls && apiCalls.length !== 0) {
        for (const { key, onLoad, onButton, server } of apiCalls) {
          if (onLoad && onLoad === true) {
            console.log("Working");
            const config2 = apiConfig[key];
            if (key === "templateJson") {
              previousResponse = await performApiRequest(
                server,
                key,
                config2.url(templateName),
                config2.method
              );
              console.log("Create Form Template", previousResponse);
              if (previousResponse.data.length !== 0) {
                dispatch({
                  type: "SET_TEMPLATE",
                  payload: previousResponse.data,
                });
              }
            }
          }
        }
      }
    };
    loading && dispatch({ type: "SET_LOADING", payload: false });

    getTemplate();

  }, [templateName]);

  const handleClear = () => {
    dispatch({ type: "SET_FORM_DATA", payload: {} });
    if (isUserStory && prevFrame !== null) {
      dispatch({ type: "SET_FORM_DATA", payload: prevFrameData });
    }
  };

  const validateForm = () => {
    const requiredFields = template.formComponent.fields.filter(
      (field) => field.required
    );
    const newFieldErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field.key || field.field]) {
        newFieldErrors[field.key || field.field] = `${field.label} is required`;
      } else {
        newFieldErrors[field.key || field.field] = "";
      }
    });

    dispatch({ type: "SET_FIELD_ERRORS", payload: newFieldErrors });

    return Object.values(newFieldErrors).every((error) => error === "");
  };
  const checkValidationStatus = (formData) => {
    if (!formData || !formData.validation) {
      //setIsSubmitDisabled(true);
      return true; // Handle case where formData or validation object doesn't exist
    }

    const validation = formData.validation;

    // Check if all validation statuses are true
    const allValid = Object.values(validation).every(
      (status) => status === true
    );

    return allValid;
  };

  function deepAssign(target, source) {
    Object.keys(source).forEach((key) => {
      if (source[key] && typeof source[key] === "object") {
        target[key] = deepAssign(target[key] || {}, source[key]);
      } else {
        target[key] = source[key];
      }
    });
    return target;
  }
  const removeValidationField = (formData) => {
    const { validation, ...formDataWithoutValidation } = formData;
    return formDataWithoutValidation;
  };
  const filterFormData = (formData, allowedFields) => {
    return Object.keys(formData)
      .filter(key => allowedFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
      }, {});
  };

  // const handleSubmit = async () => {
  //   setLoadingscreen(true)
  //   const isValid = validateForm();
  //   if (!isValid) return;
  //   const isValid2 = checkValidationStatus(formData);
  //   console.log("isvalid", isValid2);
  //   if (!isValid2) {
  //     return;
  //   }


  //   var result = removeValidationField(formData);
  //   if (allowedSubmitFields) {
  //     result = filterFormData(formData, allowedSubmitFields);
  //   }
  //   let previousResponse = null;
  //   if (apiCalls && apiCalls.length !== 0) {
  //     for (const { key, onLoad, onButton, server, operation = () => { } } of apiCalls) {
  //       if (onButton && onButton === true) {
  //         const config = apiConfig[key];
  //         let payload = {};
  //         if (key === "bexInsights") {
  //           payload = config.payloadFields(
  //             result,
  //             template.formComponent.tadabaseTableName
  //           );
  //         } else if (key === "bexAction") {
  //           payload = config.payloadFields(
  //             result,
  //             template.tableName,
  //             operation
  //           );
  //           if (autofillup.length > 0) {
  //             payload = addDynamicKeys(autofillup, payload, "bexAction");
  //             console.log("new payload", payload); // Use the new payload as neededg
  //           }
  //         }

  //         else {
  //           payload = config.payloadFields(result);
  //           if (autofillup.length > 0) {
  //             payload = addDynamicKeys(autofillup, payload);
  //             console.log("new payload", payload); // Use the new payload as needed
  //           }
  //         }
  //         var token = transformData(GetPreviousContextData());
  //         console.log("Payload created: ", payload);
  //         previousResponse = await performApiRequest(
  //           server,
  //           key,
  //           config.url,
  //           config.method,
  //           payload,
  //           config.successMessage,
  //           config.errorMessage,
  //           addNewNotifcation,
  //           token.token,
  //           handleClear
  //         );
  //         console.log("Booking Reponse ", previousResponse);
  //         // if(previousResponse.data.status===200){
  //         if (previousResponse.status === 200) {
  //           setLoadingscreen(false)
  //           AddContextData({
  //             bookingSubmitForm: result,
  //             bookingSubmitFormResponse: previousResponse
  //           });
  //         }
  //       }
  //     }
  //   }

  //   // var mergedData={
  //   //     "submitForm": "booking",

  //   // }
  // };
  // Memoize userData to avoid recalculating on every render
  const userData = useMemo(() => JSON.parse(localStorage.getItem("userData")) ?? {}, []);
  // const { access_token: token, tenant_id: tenantId, scope: role, load } = userData;
  const handleSubmit = async () => {
    setLoadingscreen(true);

    // Step 1: Validate the Form
    if (!validateForm() || !checkValidationStatus(formData)) {
      setLoadingscreen(false);
      return;
    }

    // Step 2: Handle File Uploads (if any)
    try {
      const uploadPromises = template.formComponent.fields
        .filter((field) => field.inputType === "fileUpload")
        .map(async (field) => {
          const fileData = formData[field.field];
          if (fileData) {
            const formDataToUpload = new FormData();
            formDataToUpload.append("org", `${userData.tenant_id}`);
            formDataToUpload.append("prefix", `${userData.tenant_id}/${userData.user_id}`);
            formDataToUpload.append("bucketId", "bex3d-66f0ff588c5ada289b447571");
            formDataToUpload.append("file", fileData);

            const response = await axios.post("https://feo.coolriots.ai/feo-v3/api/files", formDataToUpload);

            const uploadedFileUrl = `${userData.tenant_id}/${userData.user_id}/${response.data.files[0].file_name}`;

            dispatch({
              type: "SET_FORM_DATA",
              payload: { [field.field]: uploadedFileUrl },
            });
          }
        });

      await Promise.all(uploadPromises);
    } catch (error) {
      addNewNotifcation(error.message || "File upload failed.", "danger");
      setLoadingscreen(false);
      return;
    }

    // Step 3: Submit Form Data
    let result = removeValidationField(formData);
    if (allowedSubmitFields) {
      result = filterFormData(formData, allowedSubmitFields);
    }

    try {
      for (const { key, onButton, server } of apiCalls) {
        if (onButton) {
          const config = apiConfig[key];
          const payload = config.payloadFields(result);
          const response = await performApiRequest(server, key, config.url, config.method, payload);
          if (response.status === 200) {
            AddContextData({
              bookingSubmitForm: result,
              bookingSubmitFormResponse: response,
            });
          }
        }
      }
    } catch (error) {
      addNewNotifcation("Submission failed.", "danger");
    } finally {
      setLoadingscreen(false);
    }
  };


  function addDynamicKeys(array, payload, api = false) {
    const newPayload = { ...payload };

    array.forEach((item) => {
      const [newKey, existingKey] = item.split('=');

      if (api) {
        // Iterate over each record in ChildRecords since it's an array
        newPayload.ChildRecords = newPayload.ChildRecords.map((record) => {
          // Check if the record has the existingKey
          if (record[existingKey]) {
            // Add the new key with the value of the existing key
            record[newKey] = record[existingKey];
          }
          return record;
        });
      } else {
        // If not API, handle it as a normal object
        if (payload[existingKey]) {
          newPayload[newKey] = payload[existingKey];
        }
      }
    });

    return newPayload;
  }




  // useEffect(() => {
  //   if (template && template.tableName !== "") {
  //     const requiredFields = template.formComponent.fields.filter(
  //       (field) => field.required
  //     );
  //     // console.log(requiredFields);
  //     // console.log(formData);

  //     const isAllRequiredFieldsFilled = requiredFields.every(
  //       (field) => formData[field.key || field.field]
  //     );

  //     // setIsSubmitDisabled(!isAllRequiredFieldsFilled);
  //     const isValid2 = checkValidationStatus(formData);
  //     // setIsSubmitDisabled(!isAllRequiredFieldsFilled);
  //     console.log("setIsSubmitDisabled", isValid2, isAllRequiredFieldsFilled);
  //     if (isValid2 & isAllRequiredFieldsFilled) {
  //       console.log("setIsSubmitDisabled 1", isValid2, isAllRequiredFieldsFilled);
  //       setIsSubmitDisabled(false);
  //     } else {
  //       console.log("setIsSubmitDisabled 2", isValid2, isAllRequiredFieldsFilled);
  //       setIsSubmitDisabled(true);
  //     }

  //     //checkValidationStatus(formData)
  //   }
  // }, [formData, fieldErrors]);
  useEffect(() => {
    // Add a 2-second delay on page load to trigger the effect
    const timer = setTimeout(() => {
      if (template && template.tableName !== "") {
        const requiredFields = template.formComponent.fields.filter(
          (field) => field.required
        );

        // Check if all required fields are filled
        const isAllRequiredFieldsFilled = requiredFields.every(
          (field) => formData[field.key || field.field]
        );

        // Check validation status of formData
        const isValid2 = checkValidationStatus(formData);

        // Combine both checks to determine if submit should be disabled
        if (isValid2 && isAllRequiredFieldsFilled) {
          setIsSubmitDisabled(false);
        } else {
          setIsSubmitDisabled(true);
        }

        console.log("isValid2", isValid2);
        console.log("isAllRequiredFieldsFilled", isAllRequiredFieldsFilled);
      }
    }, 1000); // Delay of 2 seconds

    // Cleanup the timer on unmount or whenever `formData` or `fieldErrors` change
    return () => clearTimeout(timer);
  }, [formData, fieldErrors, template]);
  return loading === false ? (
    <div>
      {loadingscreen && (
        // <div className="fixed inset-0 z-50 flex items-center justify-center bg-background/80">
        //   <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center animate-spin">
        //     <LoaderPinwheelIcon className="h-10 w-10 text-primary" />
        //   </div>
        // </div>
        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AppLoader size={48} color="text-black-500" />
        </div>
      )}
      <>
        {template &&
          template.tableName !== "" &&
          template.formComponent &&
          template.formComponent.fields.length > 0 &&
          template.formComponent.isBulk === false && (
            <Card2
              body={
                <CreateForm
                  formData={formData}
                  fields={template.formComponent.fields}
                  windowWidth={windowWidth}
                  fieldErrors={fieldErrors}
                  dispatch={dispatch}
                />
              }
              footer={[
                <PrimaryButton
                  $text={submitbtn}
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                />,
                <PrimaryButton
                  $text="Clear"
                  onClick={handleClear}
                  disabled={false}
                />,
              ]}
            />
          )}
      </>
    </div>
  ) : (
    <div>
      <AppLoader />
    </div>
  );
}



function LoaderPinwheelIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2 12c0-2.8 2.2-5 5-5s5 2.2 5 5 2.2 5 5 5 5-2.2 5-5" />
      <path d="M7 20.7a1 1 0 1 1 5-8.7 1 1 0 1 0 5-8.6" />
      <path d="M7 3.3a1 1 0 1 1 5 8.6 1 1 0 1 0 5 8.6" />
      <circle cx="12" cy="12" r="10" />
    </svg>
  )
}