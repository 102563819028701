// import axios from "axios";

// // Server is for BeX-Orchestrator API (Dropdown API)
// export const server =
//     "https://prod-besorchestrator.bravebay-89a70f90.southeastasia.azurecontainerapps.io";

// export const httpRequest = axios.create({
//     withCredentials: true,
//     baseURL: server,
// });

// // Server 1 is for BeX-Insight API
// let server1;

// // Function to dynamically set server1
// const checkCcCourse = () => {
//     const userData = JSON.parse(localStorage.getItem("userData")); // Retrieve userData from localStorage
//     const { tenant_id: tenantId } = userData || {}; // Extract tenant_id safely

//     if (tenantId) {
//         console.log("tenant_id", tenantId);
//         if (tenantId === "64f9f31ab8f2b3acae3x4sfe") {
//             server1 = "https://be32-103-142-80-37.ngrok-free.app/beso";
//         } else {
//             server1 = "https://stgapp.coolriots.ai/bexo";
//         }
//     } else {
//         server1 = "https://stgapp.coolriots.ai/bexo"; // Fallback URL
//     }
// };

// // Check the course and set server1
// checkCcCourse();

// export const httpRequest1 = axios.create({
//     withCredentials: true,
//     baseURL: server1,
// });

// // Server 2 is for Test API
// export const server2 = "https://app.coolriots.ai/feo-v3";

// export const httpRequest2 = axios.create({
//     withCredentials: true,
//     baseURL: server2,
// });

// // Server 3 is for BeX-Action API
// export const server3 = "https://app.coolriots.ai";
// export const httpRequest3 = axios.create({
//     withCredentials: true,
//     baseURL: server3,
// });





// // project o - main
// const serverO = 'https://app.coolriots.ai'
// const serverOPrefix = "/projecto/api/v1";

// export const projectOHttpClient = axios.create({
//     withCredentials: true,
//     baseURL: `${serverO}${serverOPrefix}`,
    
// });

// const websocketO = 'wss://app.coolriots.ai'
// const websocketOPrefix = "/projecto/api/v1";
// export const BEX_WEBSOCKET_URL = `${websocketO}${websocketOPrefix}`;

// // project o - stg
// const serverOStg = 'https://stgapp.coolriots.ai'
// const serverOPrefixStg = "/projecto/api/v1";

// export const projectOHttpClientStg = axios.create({
//     withCredentials: true,
//     baseURL: `${serverOStg}${serverOPrefixStg}`,
    
// });
// const websocketOStg = 'wss://stgapp.coolriots.ai'
// const websocketOPrefixStg = "/projecto/api/v1";
// export const BEX_WEBSOCKET_URL_STG = `${websocketOStg}${websocketOPrefixStg}`;

// /**
//  * A Dynamic WebSocket client maker with event interceptors.
//  * @param {string} endpoint - The specific WebSocket endpoint to connect to (e.g., "/notifications").
//  * @param {Object} [options={}] - Options to configure WebSocket events.
//  * @param {Function} [options.onOpen] - Optional function to be called on WebSocket open event.
//  * @param {Function} [options.onMessage] - Optional function to be called on WebSocket message event.
//  * @param {Function} [options.onError] - Optional function to be called on WebSocket error event.
//  * @param {Function} [options.onClose] - Optional function to be called on WebSocket close event.
//  * @returns {WebSocket} The WebSocket instance for managing connections.
//  */
// export const createWebSocketClient = (
//     endpoint,
//     deployment = 'main',
//     {
//         onOpen = () => console.log("WebSocket connected"),
//         onMessage = (message) => console.log("Received:", message.data),
//         onError = (error) => console.error("WebSocket error:", error),
//         onClose = () => console.log("WebSocket closed")
//     } = {}
// ) => {
//     // Construct WebSocket URL by combining base URL, prefix, and endpoint
//     const WS_BASE = deployment === "main" ? BEX_WEBSOCKET_URL : BEX_WEBSOCKET_URL_STG;
//     const wsURL = `${WS_BASE}${endpoint}`;

//     // Create WebSocket instance
//     const wsClient = new WebSocket(wsURL);

//     // Set up WebSocket event listeners with optional interceptors
//     wsClient.onopen = (event) => onOpen(event);
//     wsClient.onmessage = (message) => onMessage(message);
//     wsClient.onerror = (error) => onError(error);
//     wsClient.onclose = (event) => onClose(event);

//     /**
//      * Function to send a message to the WebSocket server.
//      * @param {string | ArrayBuffer | Blob | ArrayBufferView} data - The data to be sent.
//      */
//     wsClient.sendMessage = (data) => {
//         if (wsClient.readyState === WebSocket.OPEN) {
//             wsClient.send(data);
//         } else {
//             console.warn("WebSocket is not open. Unable to send message.");
//         }
//     };

//     return wsClient;
// };






import axios from "axios";

// Server is for BeX-Orchestrator API (Dropdown API)
export const server =
    "https://prod-besorchestrator.bravebay-89a70f90.southeastasia.azurecontainerapps.io";

export const httpRequest = axios.create({
    withCredentials: true,
    baseURL: server,
});

// Server 1 is for BeX-Insight API
// export const server1 = "https://besinsight-test.salmonstone-ff69e304.southeastasia.azurecontainerapps.io";

// Server 1 is for BeX-Insight API
let server1;

// // Function to dynamically set server1
// const checkCcCourse = () => {
//     //temporary
//     const userData = JSON.parse(localStorage.getItem("userData")); // Retrieve userData from localStorage
//     const { tenant_id: tenantId } = userData || {}; // Extract tenant_id safely

//     if (tenantId) {
//         console.log("tenant_id", tenantId);
//         if (tenantId === "64f9f31ab8f2b3acae3x4sfe") {
//             // server1 = "https://be32-103-142-80-37.ngrok-free.app/beso";
//             server1 = "https://stgapp.coolriots.ai/bexo";
//         } else {
//             server1 = "https://stgapp.coolriots.ai/bexo";
//         }
//     } else {
//         server1 = "https://stgapp.coolriots.ai/bexo"; // Fallback URL
//     }
// };

// // Check the course and set server1
// checkCcCourse();
server1 = "https://stgapp.coolriots.ai/bexo";

export const httpRequest1 = axios.create({
    withCredentials: true,
    baseURL: server1,
});

// Server 2 is for Test API
// export const server2 = "https://besca-server.vercel.app";
export const server2 = "https://app.coolriots.ai/feo-v3";

export const httpRequest2 = axios.create({
    withCredentials: true,
    baseURL: server2,
});

// Server 3 is for BeX-Action API https://stgapp.coolriots.ai/bexo/create/open-booking-registration
// export const server3 = "https://bexo.besorchestrator.app";
// export const server3 = "https://app.coolriots.ai";
export const server3 ="https://stgapp.coolriots.ai";
export const httpRequest3 = axios.create({
    withCredentials: true,
    baseURL: server3,
    
});




// project o - main
const serverO = 'https://app.coolriots.ai'
const serverOPrefix = "/projecto/api/v1";

export const projectOHttpClient = axios.create({
    withCredentials: true,
    baseURL: `${serverO}${serverOPrefix}`,
    
});

const websocketO = 'wss://app.coolriots.ai'
const websocketOPrefix = "/projecto/api/v1";
export const BEX_WEBSOCKET_URL = `${websocketO}${websocketOPrefix}`;

// project o - stg
const serverOStg = 'https://stgapp.coolriots.ai'
const serverOPrefixStg = "/projecto/api/v1";

export const projectOHttpClientStg = axios.create({
    withCredentials: true,
    baseURL: `${serverOStg}${serverOPrefixStg}`,
    
});
const websocketOStg = 'wss://stgapp.coolriots.ai'
const websocketOPrefixStg = "/projecto/api/v1";
export const BEX_WEBSOCKET_URL_STG = `${websocketOStg}${websocketOPrefixStg}`;

/**
 * A Dynamic WebSocket client maker with event interceptors.
 * @param {string} endpoint - The specific WebSocket endpoint to connect to (e.g., "/notifications").
 * @param {Object} [options={}] - Options to configure WebSocket events.
 * @param {Function} [options.onOpen] - Optional function to be called on WebSocket open event.
 * @param {Function} [options.onMessage] - Optional function to be called on WebSocket message event.
 * @param {Function} [options.onError] - Optional function to be called on WebSocket error event.
 * @param {Function} [options.onClose] - Optional function to be called on WebSocket close event.
 * @returns {WebSocket} The WebSocket instance for managing connections.
 */
export const createWebSocketClient = (
    endpoint,
    deployment = 'main',
    {
        onOpen = () => console.log("WebSocket connected"),
        onMessage = (message) => console.log("Received:", message.data),
        onError = (error) => console.error("WebSocket error:", error),
        onClose = () => console.log("WebSocket closed")
    } = {}
) => {
    // Construct WebSocket URL by combining base URL, prefix, and endpoint
    const WS_BASE = deployment === "main" ? BEX_WEBSOCKET_URL : BEX_WEBSOCKET_URL_STG;
    const wsURL = `${WS_BASE}${endpoint}`;

    // Create WebSocket instance
    const wsClient = new WebSocket(wsURL);

    // Set up WebSocket event listeners with optional interceptors
    wsClient.onopen = (event) => onOpen(event);
    wsClient.onmessage = (message) => onMessage(message);
    wsClient.onerror = (error) => onError(error);
    wsClient.onclose = (event) => onClose(event);

    /**
     * Function to send a message to the WebSocket server.
     * @param {string | ArrayBuffer | Blob | ArrayBufferView} data - The data to be sent.
     */
    wsClient.sendMessage = (data) => {
        if (wsClient.readyState === WebSocket.OPEN) {
            wsClient.send(data);
        } else {
            console.warn("WebSocket is not open. Unable to send message.");
        }
    };

    return wsClient;
};