import React, { useState, useMemo } from "react";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import axios from "axios";
const FileUploadField = ({ item, formData, dispatch, userData }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    userData = useMemo(() => JSON.parse(localStorage.getItem("userData")) ?? {}, []);
    const handleFileChange = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            setErrorMessage("Please select a file.");
            return;
        }

        // Validate file type and size
        const allowedTypes = [
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/jpeg",
            "image/jpg",
        ];
        const maxFileSize = 5 * 1024 * 1024; // 5 MB

        if (!allowedTypes.includes(file.type)) {
            setErrorMessage("Invalid file type. Allowed: PDF, Word, Excel, JPEG.");
            return;
        }
        if (file.size > maxFileSize) {
            setErrorMessage("File size exceeds 5 MB.");
            return;
        }

        setErrorMessage("");
        setLoading(true);

        // const { access_token: token, tenant_id: tenantId, scope: role, load } = userData;
        try {

            const formDataToUpload = new FormData();
            formDataToUpload.append("org", `${userData.tenant_id}`);
            formDataToUpload.append("prefix", `${userData.tenant_id}/${userData.user_id}`);
            formDataToUpload.append("bucketId", "bex3d-66f0ff588c5ada289b447571");
            formDataToUpload.append("file", file);

            const response = await axios.post("https://feo.coolriots.ai/feo-v3/api/files", formDataToUpload);

            const uploadedFileUrl = `${userData.tenant_id}/${userData.user_id}/${response.data.files[0].file_name}`;

            dispatch({
                type: "SET_FORM_DATA",
                payload: {
                    ...formData,
                    [item.key || item.field]: uploadedFileUrl,
                },
            });
        } catch (error) {
            console.error("File upload error:", error);
            setErrorMessage("Failed to upload file. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <div className="space-y-2">
                <Label htmlFor={item?.key || item?.field}>
                    {item?.label} {item?.required && "*"}
                </Label>
                <Input
                    type="file"
                    id={item?.key || item?.field}
                    onChange={handleFileChange}
                    disabled={loading}
                    style={{ width: "100%" }}
                />
                {loading && <p style={{ color: "blue" }}>Uploading...</p>}
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
        </div>
    );
};

export default FileUploadField;
